import React, { Component } from 'react'
import logo from './logo.svg'
import gif from './video.gif'
import styled, { createGlobalStyle } from 'styled-components'
import Seo from '../components/seo'

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Menlo,Monaco,'Courier New',mono,monospace;
    background-color: #e8390e;
    font-size: 12px;
  }
`
const BgImage = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${gif});
  background-size: cover;
  background-position: top center;
`

const Links = styled.div`
  display: flex;
  flex-direction: row;
`
const Container = styled.div`
  overflow-x: hidden;
  -wekit-oveflow-scrolling: touch;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items:center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0,0,0,0.35);
`
const Logo = styled.img.attrs({
  width: 240,
  height: 240 / 861 * 470,
  src: logo
})`
  margin-bottom: 40px;
  flex-shrink: 0;
`
const P = styled.p`
  color: white;
  line-height: 1.5em;
  max-width: 300px;
  margin-bottom: 20px;
  text-align: center;
`
const Link = styled.a`
  margin-bottom: 20px;
  padding: 10px;
  color: white;
  text-decoration: none;
  &:hover {
    color: #e8390e;
  }
`

export default class App extends Component {
  render() {
    return (
      <Container>
        <GlobalStyle />
        <Seo title="Salon S" />
        <BgImage />
        <Container>
          <Logo />
          <P>
            SALON S
            <br />
            <br />
            両生類･爬虫類専用　展示即売会レンタルスペース
            <br />
            会場利用は完全紹介制となっておりますので、ご利用を希望されるショップ様およびブリーダー様は、ご利用をされた方に一度お問い合わせ下さいませ。
          </P>
          <Links>
            <Link href='https://www.twitter.com/salon__s'>Twitter</Link>
            <Link href='https://www.instagram.com/salon______s'>Instagram</Link>
            <Link href='https://www.facebook.com/SALON-S-105723705364173/'>Facebook</Link>
          </Links>
        </Container>
      </Container>
    )
  }
}
